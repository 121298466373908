import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { RootState } from '@/store';
import { OperationResource } from '@/store/types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

const FormOperationsResources = () => {
  const { simulationParams } = useContext(SimulationCtx);
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );

  const handleResources = (
    operation_id: string,
    operation_resources: OperationResource[],
  ) => {
    const c = operation_resources.map(
      (item: OperationResource, idx: number) => {
        const resource =
          selectedScenario?.data.resources.byId[item.RESOURCE_ID.value];

        const isLastItem = idx === operation_resources.length - 1;
        const borderBottomStyle = isLastItem
          ? {}
          : { borderBottom: 'solid 1px #dadada' };

        if (!resource) return;
        return (
          <div
            key={idx}
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 50px 50px',
              padding: 5,
              gap: 20,
              ...borderBottomStyle,
            }}
          >
            <div>
              {
                selectedScenario?.data.shift_calendar.byId[
                  resource.RESOURCE_SHIFT_CALENDAR_ID.value
                ].RESOURCE_SHIFT_CALENDAR.value
              }
            </div>
            <div style={{ paddingRight: 10, textAlign: 'center' }}>
              {item.RESOURCE_PRIORITY.value}
            </div>
          </div>
        );
      },
    );

    return (
      <>
        <div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 50px 50px',
              padding: 5,
              gap: 20,

              borderBottom: 'solid 1px #dadada',
              fontSize: 11,
              fontWeight: 'bold',
            }}
          >
            <div>Resource</div>
            <div>Shift</div>
            <div style={{ textAlign: 'center' }}>Priority</div>
          </div>
          {c}
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
            }}
          ></div>
        </div>
      </>
    );
  };

  const filteredObjects: OperationResource[] = selectedScenario
    ? selectedScenario.data.operations_resources.allIds
        .map((id) => selectedScenario?.data.operations_resources.byId[id])
        .filter((obj) => obj.OPERATION_ID.value === simulationParams.formItem)
        .sort(
          (a, b) =>
            parseInt(a.RESOURCE_PRIORITY.value) -
            parseInt(b.RESOURCE_PRIORITY.value),
        )
    : [];

  if (!simulationParams.formItem) return 'Not found';

  return handleResources(simulationParams.formItem, filteredObjects);
};

export default FormOperationsResources;
