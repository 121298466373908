import { Layout } from '@/components/Layout';
import { WIP_CATEGORIES } from '@/constants/categories';
import {
  SimulationCtx,
  SimulationProvider,
} from '@/contexts/Simulation/SimulationCtx';
import { CreateSimulationForm } from '@/features/CreateSimulationForm';
import { TSimulationMenuOptions } from '@/store/types';
import { Drawer, Tabs } from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext } from 'react';
import { SimulationsList } from '../SimulationsList';
import { LeftMenu } from './LeftMenu';
import { TableView } from './TableView';
import { Loader } from '@/components/Loader';
import { FormView } from './FormView';

const mapAndSortData = (data: TopMenuCategories[]) => {
  return data
    .filter((item) => item.order >= 0)
    .sort((a, b) => b.order - a.order) // sort in descending order
    .map((item) => ({
      // map to new format
      key: item.key, // converting index to string and adding 1 because array index is zero-based
      label: item.label,
    }));
};

type TopMenuCategories = {
  key: TSimulationMenuOptions;
  label: string;
  endpoint: string;
  order: number;
};

const ContentMenu = () => {
  const MenuCategories: TopMenuCategories[] = [...WIP_CATEGORIES];
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);

  const handleChange = (value: TSimulationMenuOptions) => {
    setSimulationParams(
      produce((draft) => {
        draft.simulationOptionSelected = value;
      }),
    );
    console.log(value);
  };

  return (
    <div style={{ paddingTop: 20 }}>
      <Tabs
        fontSize={13}
        data={mapAndSortData([
          ...MenuCategories,
          {
            key: 'scenario_settings',
            label: 'Scenario Settings',
            endpoint: '',
            order: 1000,
          },
        ])}
        onChange={handleChange}
        selectedKey={simulationParams.simulationOptionSelected}
      />
    </div>
  );
};

const Content = () => {
  const { simulationParams } = useContext(SimulationCtx);

  return (
    <>
      {!simulationParams.simulationForm ? (
        <>
          {simulationParams.simulationStarted ? (
            <TableView />
          ) : (
            <SimulationsList />
          )}
        </>
      ) : (
        <CreateSimulationForm />
      )}
    </>
  );
};

const SimulationContent = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const handleDrawer: React.Dispatch<React.SetStateAction<boolean>> = (
    value: React.SetStateAction<boolean>,
  ) => {
    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen:
        typeof value === 'function' ? value(prevState.drawerOpen) : value,
    }));

    console.log(simulationParams);
  };
  return (
    <>
      {simulationParams.loaderMessage.toString()}
      {simulationParams.loaderMessage && (
        <Loader message={simulationParams.loaderMessage} />
      )}

      <Drawer
        closeOnClickOutside={true}
        showCloseButton={false}
        showBackdrop={true}
        width={500}
        fromRight={true}
        isOpen={simulationParams.drawerOpen}
        setIsOpen={handleDrawer}
      >
        <FormView />
        {/* 
        {simulationParams.simulationOptionSelected}
        {JSON.stringify(simulationParams.formItem)}
        <br></br>
        {JSON.stringify(simulationParams)} */}
      </Drawer>

      <Layout
        menu={<LeftMenu /* setDrawerOpen={setDrawerOpen} */ />}
        menuWidth={'300px'}
        menuCollapsed={false}
        content={<Content />}
        contentMenu={<ContentMenu />}
        contentMenuShow={simulationParams.simulationStarted}
      />
    </>
  );
};

const Simulation = () => {
  return (
    <>
      <SimulationProvider>
        <SimulationContent />
      </SimulationProvider>
    </>
  );
};

export default Simulation;
