const statusMapping = {
  M: 'Moving',
  S: 'Setup',
  F: 'Failure',
  N: 'New Resource',
  D: 'Defect Resource',
};

export const mapStatusToFullString = (status: string) => {
  const firstLetter = status.charAt(0);
  return statusMapping[firstLetter] || 'Unknown Status';
};
