/* eslint-disable @typescript-eslint/no-explicit-any */

import { AppDispatch, RootState } from '@/store';
import { changeExpanded } from '@/store/storeSlice';
import {
  ProductionOrderTree,
  SiteTree,
  TreeItem,
  TSimulationMenuOptions,
  WipItemParams,
  WipTree,
  WipTreeElement,
  WorkPlanTree,
} from '@/store/types';
import { generateUID, unixTimestampToDate } from '@/utils/helpers';
import {
  Button,
  Input,
  Label,
  Table,
  Tooltip,
  useDebounceCallback,
} from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { ReactNode, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from '../ContextMenu';
import { sortChildrenByTitleAsc } from '../helpers';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';

export interface ProductTreeRender extends WipItemParams {
  PO_END_DATE_TIMESTAMP: TreeItem<number>;
  PO_START_DATE_TIMESTAMP: TreeItem<number>;
  PRODUCT_ID: TreeItem;
  PRODUCTION_ORDER: TreeItem;
  PRODUCTION_ORDER_ID: TreeItem;
  WORK_PLAN_ID: TreeItem;
  numChildren: number;
  SITE: string;
  PRODUCT: string;
  WORKPLAN: string;
  START_DATE: string;
  END_DATE: string;
  PTT: number;
  CURRENT_STEP: string;
}

type ProductTreeRenderGeneric = {
  [K in keyof ProductTreeRender]: string | number | ReactNode;
};
type TProductStructure = {
  id: string;
  data: ProductTreeRenderGeneric;
  children: TProductStructure[];
  isClickable?: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  area: keyof WipTree;
};

function calculateDaysBetweenTimestamps(
  timestamp1: number,
  timestamp2: number,
): number {
  const millisecondsPerDay = 24 * 60 * 60; // Number of milliseconds in a day
  const differenceInMilliseconds = Math.abs(timestamp2 - timestamp1);
  return Math.ceil(differenceInMilliseconds / millisecondsPerDay);
}

const ProductionOrders = () => {
  const { setSimulationParams } = useContext(SimulationCtx);
  const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);

  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );

  const [filterQuery, setFilterQuery] = useState<string>('');

  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFilterQuery(event.target.value);
    },
    500,
  );

  const handleWorkplan = (workplan: WorkPlanTree) => {
    const c = workplan.children.map((item: string, idx: number) => {
      const isLastItem = idx === workplan.children.length - 1;
      const borderBottomStyle = isLastItem
        ? {}
        : { borderBottom: 'solid 1px #dadada' };

      return (
        <div
          key={idx}
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            padding: 5,
            ...borderBottomStyle,
          }}
        >
          <div style={{ paddingRight: 10 }}>
            {selectedScenario?.data.operations.byId[item].STEP.value}
          </div>
          <div>
            {selectedScenario?.data.operations.byId[item].OPERATION.value}
          </div>
        </div>
      );
    });

    return <>{c}</>;
  };

  type TCurrentStep = {
    stepNumber: number;
    name: string;
    processTime: number;
    isCurrent: boolean;
    percentageDone: number;
  };
  const handleWorkplanCurrentStep = (workplan: WorkPlanTree, step: number) => {
    const stepInt = Math.floor(step);
    const stepDecimal = Math.round((step % 1) * 100);

    const wpArr: TCurrentStep[] = [];

    //TODO: Replace process time with something else
    workplan.children.forEach((item: string) => {
      wpArr.push({
        stepNumber: selectedScenario
          ? parseInt(selectedScenario?.data.operations.byId[item].STEP.value)
          : 0,
        name: selectedScenario
          ? selectedScenario?.data.operations.byId[item].OPERATION.value
          : '',
        processTime: 0,

        isCurrent: selectedScenario
          ? parseInt(
              selectedScenario?.data.operations.byId[item].STEP.value,
            ) === stepInt
          : false,
        percentageDone: stepDecimal,
      });
    });

    let ellapsedTime: number = 0;
    let remainingTime: number = 0;
    let currentStep: string = '';
    let currentStepPercentage: number = 0;
    let afterCurrent: boolean = false;
    wpArr.forEach((item: TCurrentStep) => {
      if (!item.isCurrent && !afterCurrent && stepInt > 0) {
        ellapsedTime += item.processTime;
      } else if (!item.isCurrent && afterCurrent) {
        remainingTime += item.processTime;
      } else {
        //item is the current one;
        currentStep = item.name;
        afterCurrent = true;
        currentStepPercentage = item.percentageDone;
        ellapsedTime += item.processTime * (item.percentageDone / 100);
        remainingTime += item.processTime * ((100 - item.percentageDone) / 100);
      }
    });

    /*  return `
    ${currentStep}
    Percentage: ${(ellapsedTime * 100) / (remainingTime + ellapsedTime)}
    Percentage: ${ellapsedTime} ${remainingTime}
    `; */

    return (
      <Tooltip
        key={generateUID()}
        title={
          <>
            <Label tag="textextrasmall_strong" marginBottom={0}>
              Current Step
            </Label>
            <div>{currentStep}</div>
            <Label tag="textextrasmall_strong" marginBottom={0} marginTop={10}>
              Total
            </Label>
            <div>
              {Math.round(
                (ellapsedTime * 100) / (remainingTime + ellapsedTime),
              )}
              % completed
            </div>
          </>
        }
        color="light"
      >
        <div style={{ display: 'inline-block' }}>
          <div
            style={{
              border: 'solid 1px #e6e6e6',
              width: 50,
              height: 5,
            }}
          >
            <div
              style={{
                height: '100%',
                backgroundColor: '#8a00e5',
                width:
                  ellapsedTime === 0
                    ? 0
                    : `${Math.round(
                        (ellapsedTime * 100) / (remainingTime + ellapsedTime),
                      )}%`,
              }}
            ></div>
          </div>
        </div>
      </Tooltip>
    );

    return {
      ellapsedTime,
      remainingTime,
      currentStep,
      currentStepPercentage,
    };
  };

  const TransformProduct = (item: ProductionOrderTree) => {
    let numChildren: number = 0;

    item.children.forEach((child) => {
      if (selectedScenario) {
        const order = selectedScenario.data.production_orders.byId[child];
        if (order && !order.is_disabled) {
          numChildren++;
        }
      }
    });

    const newItem: ProductTreeRenderGeneric = {
      key: item.PRODUCT_ID.originalValue,
      title: item.PRODUCTION_ORDER.value,
      is_disabled: item.is_disabled,
      is_wip: item.is_wip,
      is_deleted: item.is_deleted,
      is_clicked: item.is_clicked,
      is_selectable: item.is_selectable,
      is_selected: item.is_selected,
      is_hidden: item.is_hidden,
      is_match: item.is_match,
      is_open: item.is_open,
      parent: '',
      children: [],
      PO_END_DATE_TIMESTAMP: '',
      PO_START_DATE_TIMESTAMP: '',
      PRODUCT_ID: '',
      PRODUCTION_ORDER: item.PRODUCTION_ORDER.value,
      PRODUCTION_ORDER_ID: item.PRODUCTION_ORDER_ID.value,
      WORK_PLAN_ID: item.WORK_PLAN_ID.value,
      PRODUCT: selectedScenario
        ? selectedScenario.data.products.byId[item.PRODUCT_ID.value].PRODUCT
            .value
        : '',
      SITE: selectedScenario
        ? selectedScenario.data.sites.byId[item.SITE_ID.value].SITE_ABBREVIATION
            .value
        : '',
      WORKPLAN: selectedScenario ? (
        <>
          <Tooltip
            key={
              'Tooltip_WP_' +
              selectedScenario.data.work_plans.byId[item.WORK_PLAN_ID.value]
                .WORK_PLAN_ID.value +
              generateUID()
            }
            color="light"
            title={handleWorkplan(
              selectedScenario.data.work_plans.byId[item.WORK_PLAN_ID.value],
            )}
          >
            <div style={{ display: 'inline-block' }}>
              <Button type="headless" allCaps={false}>
                {
                  selectedScenario.data.work_plans.byId[item.WORK_PLAN_ID.value]
                    .WORK_PLAN.value
                }
              </Button>
            </div>
          </Tooltip>
        </>
      ) : (
        ''
      ),
      CURRENT_STEP: (
        <>
          {selectedScenario
            ? handleWorkplanCurrentStep(
                selectedScenario.data.work_plans.byId[item.WORK_PLAN_ID.value],
                item.PO_LAST_STEP_WIP.value,
              )
            : ''}
        </>
      ),
      /*  <div style={{ border: 'solid 1px #e6e6e6', width: 50, height: 5 }}>
          <div
            style={{
              height: '100%',
              backgroundColor: '#8a00e5',
              width: `${Math.round(
                (item.PO_LAST_STEP_WIP.value * 100) /
                  (selectedScenario?.data?.work_plans?.byId?.[
                    item.WORK_PLAN_ID.value
                  ]?.children?.length ?? 1),
              )}%`,
            }}
          ></div>
        </div> */
      START_DATE: unixTimestampToDate(
        item.PO_START_DATE_TIMESTAMP.value,
        'dd.mm.yyyy',
      ),
      END_DATE: unixTimestampToDate(
        item.PO_END_DATE_TIMESTAMP.value,
        'dd.mm.yyyy',
      ),
      PTT: calculateDaysBetweenTimestamps(
        item.PO_START_DATE_TIMESTAMP.value,
        item.PO_END_DATE_TIMESTAMP.value,
      ),
      numChildren: numChildren,
    };

    return newItem;
  };

  const generateObjectWithChildren = (
    sitesObject: WipTreeElement<SiteTree>,
    originalObject: WipTreeElement<ProductionOrderTree>,
  ): TProductStructure[] => {
    const objectWithChildren: any[] = [];
    const itemMap: { [itemId: string]: TProductStructure } = {};

    sitesObject.allIds.forEach((itemId: string) => {
      const item = sitesObject.byId[itemId];

      const countSites = originalObject.allIds.reduce((count, id) => {
        if (
          originalObject.byId[id].SITE_ID.value ===
            item.SITE_ID.originalValue &&
          originalObject.byId[id].PARENT_PRODUCTION_ORDER_ID.value === null
        ) {
          return count + 1;
        } else {
          return count;
        }
      }, 0);

      const newItem: TProductStructure = {
        id: item.SITE_ID.originalValue,
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        area: 'sites',
        data: {
          key: item.SITE_ID.originalValue,
          title: item.SITE.value,
          is_wip: item.is_wip,
          is_deleted: item.is_deleted,
          is_disabled: item.is_disabled,
          is_clicked: item.is_clicked,
          is_selectable: item.is_selectable,
          is_selected: item.is_selected,
          is_hidden: item.is_hidden,
          is_match: item.is_match,
          is_open: item.is_open,
          parent: '',
          children: [],
          PO_END_DATE_TIMESTAMP: '',
          PO_START_DATE_TIMESTAMP: '',
          CURRENT_STEP: '',
          PRODUCT_ID: '',
          PRODUCTION_ORDER: item.SITE.value + ' [' + countSites + ']',
          PRODUCTION_ORDER_ID: '',
          WORK_PLAN_ID: '',
          numChildren: '',
          SITE: '',
          PRODUCT: '',
          WORKPLAN: '',
          START_DATE: '',
          PTT: '',
          END_DATE: '',
        },
        children: [],
      };

      objectWithChildren.push(newItem);
      itemMap[itemId] = newItem;
    });

    originalObject.allIds.forEach((itemId: string) => {
      const item = originalObject.byId[itemId];
      const parentId = item.PARENT_PRODUCTION_ORDER_ID.value;
      const siteId = item.SITE_ID.originalValue;
      if (!sitesObject.byId[siteId]) return;

      const newItem: TProductStructure = {
        area: 'production_orders',
        isExpanded: item.is_open,
        isDisabled: item.is_disabled,
        id: item.PRODUCTION_ORDER_ID.value,
        data: TransformProduct(item),
        children: [],
      };

      // If the parent ID is 'root', add the item directly to the object
      if (parentId === null && !item.is_deleted) {
        objectWithChildren
          .find((obj) => obj.id === siteId)
          .children.push(newItem);
        // itemMap[siteId].children.push(newItem);
      }

      // Store the item in the map for later reference
      if (!item.is_deleted) itemMap[itemId] = newItem;
    });

    // Iterate over each item again to assign children to their respective parent nodes
    originalObject.allIds.forEach((itemId: string) => {
      const item = originalObject.byId[itemId];
      const parentId = item.PARENT_PRODUCTION_ORDER_ID;
      const siteId = item.SITE_ID.originalValue;
      if (!sitesObject.byId[siteId]) return;

      // If the item has a parent, add it as a child to the respective parent node
      if (
        parentId !== null &&
        itemMap[parentId.originalValue] &&
        !item.is_deleted
      ) {
        itemMap[parentId.originalValue].children.push(itemMap[itemId]);
      }
    });

    return sortChildrenByTitleAsc(objectWithChildren);
  };

  if (!selectedScenario) return <>No Scenario</>;

  const objectWithChildren = generateObjectWithChildren(
    selectedScenario.data.sites,
    selectedScenario.data.production_orders,
  );

  const TableHeaders: TableHeaderType[] = [
    {
      title: 'Production Order',
      align: 'left',
      columnKey: 'PRODUCTION_ORDER',
      columnWidth: '400px',
    },
    { title: 'Product', align: 'center', columnKey: 'PRODUCT' },
    { title: 'Site', align: 'center', columnKey: 'SITE' },
    { title: 'Workplan', align: 'center', columnKey: 'WORKPLAN' },

    { title: 'Start Date', align: 'center', columnKey: 'START_DATE' },
    { title: 'End Date', align: 'center', columnKey: 'END_DATE' },
    {
      title: 'Planned Throughput Time',
      align: 'center',
      columnKey: 'PTT',
      columnWidth: '80px',
    },

    {
      title: 'Qty Assembled Parts',
      align: 'center',
      columnKey: 'numChildren',
      columnWidth: '100px',
    },
    {
      title: 'Completion Percentage',
      align: 'center',
      columnKey: 'CURRENT_STEP',
      columnWidth: '80px',
    },
  ];

  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'production_orders') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleClick = (value: TableRowData) => {
    /* testDispatch(value) */
    if (!value.children) return;
    if (value.children.length <= 0) return;

    dispatch(
      changeExpanded({
        id: value.id,
        isExpanded: value.isExpanded ?? false,
        area: value.area as keyof WipTree,
      }),
    );
  };

  const handleMenuRight = (
    value: TableRowData,
    event?: React.MouseEvent<HTMLTableRowElement>,
  ) => {
    const area = value.area as keyof WipTree;

    if (area !== 'production_orders') return;
    // event && setAnchorEl(event.currentTarget);
    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input
          id="Filter"
          onChange={handleInputChangeDebounced}
          placeholder="Filter"
        />
      </div>

      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />

      <Table
        headers={TableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        filterQuery={filterQuery}
        rows={objectWithChildren}
        pointerCursor={true}
        typographyTag="textsmall"
        onRowClick={(value) => handleClick(value)}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />
    </>
  );
};

export default ProductionOrders;
